exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-fr-js": () => import("./../../../src/pages/about.fr.js" /* webpackChunkName: "component---src-pages-about-fr-js" */),
  "component---src-pages-apps-mdx": () => import("./../../../src/pages/apps.mdx" /* webpackChunkName: "component---src-pages-apps-mdx" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-of-uses-en-mdx": () => import("./../../../src/pages/terms-of-uses.en.mdx" /* webpackChunkName: "component---src-pages-terms-of-uses-en-mdx" */),
  "component---src-pages-terms-of-uses-fr-mdx": () => import("./../../../src/pages/terms-of-uses.fr.mdx" /* webpackChunkName: "component---src-pages-terms-of-uses-fr-mdx" */)
}

